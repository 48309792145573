import React from "react";
import CookieConsent from "react-cookie-consent";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./styles.css";
import ImageOne from "./images/267596651.jpeg";
import ImageThree from './images/87447610.svg';
import ImageTwo from "./images/108339529.jpeg";
import ImageFour from "./images/69935077.jpeg";
import Image_1 from "./images/207612045_g.jpeg";
import Image_2 from "./images/163886911.jpeg";
import foto_1 from "./images/Foto1.png";
import foto_2 from "./images/Foto2.PNG";
import foto_3 from "./images/Foto3.png";
import foto_4 from "./images/Foto4.png";
import foto_5 from "./images/Foto5.png";
import ScrollToButton from "./components/ScrollToButton";
import Section from "./components/Section";
import LogoOne from "./images/logo.svg";
import YouTube from "./images/youtube-with-circle.svg";




const sections = ["header", "cbam", "versionen", "footer"];

export default function App() {
    const cbamRef = React.useRef(null);


    return (
        <html>
        <head>

        </head>
        <body>
        <div className="App">
            <CookieConsent
                location="bottom"
                buttonText="Ich bin einverstanden!"
                cookieName="cbamCookieName"
                style={{background: '#420d09', }}
                buttonStyle={{color: '#000000', fontSize: '15px'}}>
                <span style={{ fontSize: '13px' }}>
                Diese Seite nutzt mehrere Arten von Cookies:
                Technische und funktionale Cookies benötigen wir zwingend, damit bei Ihrem Besuch unserer Website alles gelingt.
                Darüber hinaus setzen wir Marketing-Cookies, damit wir Sie auf unseren Seiten wiedererkennen und den Erfolg unserer Kampagnen messen können.
                Sind Sie über 16? Dann willigen Sie mit „Ich bin einverstanden!“ in die Nutzung aller Cookies ein – und schon gehts weiter.</span>
            </CookieConsent>

            <div style={{position: 'fixed', top: '0', zIndex: '1005', minWidth: 100+'%', height: 'auto', backgroundColor: '#012850'}}>
                <img src={LogoOne} alt="B1" width="85" height="58" className="h-full mt-4 ml-10"/>
                <p style={{color: '#ffffff', fontFamily: 'arial', fontSize: 30+'px', marginTop: -3+'rem', marginLeft: 8.5+'rem'}}>CB_AngebotsManager</p>
                <div style={{color: '#ffffff', marginLeft: 170+'px', marginTop: 5+'px', float: 'left', fontFamily: 'Arial', fontSize: 18+'px', fontWeight: 'bold',}}>
                <ScrollToButton toId="header">Vision</ScrollToButton>
                </div>
                <div style={{float: 'left', marginLeft: 20+'px', marginTop: 5+'px', color: '#ffffff', fontFamily: 'Arial', fontSize: 18+'px', fontWeight: 'bold',}}>
                <ScrollToButton duration={1500} toRef={cbamRef}>CBAM</ScrollToButton>
                </div>
                <div style={{float: 'left', marginLeft: 20+'px', marginTop: 5+'px', color: '#ffffff', fontFamily: 'Arial', fontSize: 18+'px', fontWeight: 'bold',}}>
                    <ScrollToButton duration={1500} toId="versionen">Versionen</ScrollToButton>
                </div>
                <div style={{float: 'left', marginLeft: 20+'px', marginTop: 2+'px', color: '#ffffff', fontFamily: 'Arial', fontSize: 20+'px', fontWeight: 'bold',}}>
                    <ScrollToButton duration={1500} toId="footer">§</ScrollToButton>
                </div>
                <div style={{float: 'left', marginLeft: 20+'px', marginTop: 2+'px', color: '#ffffff', fontFamily: 'Arial', fontSize: 20+'px',}}>
                    <a style={{color: '#ffffff', fontFamily: 'Arial',}} href="https://cb-oc.cb-angebotsmanager.de/" target="_blank" rel="noreferrer">Login</a>
                </div>
            </div>
            <Section id={sections[0]}>
            <div style={{ marginTop: 0+'rem', height: 'auto'}}>
                <Carousel autoPlay={true} interval={5000} showArrows={false} infiniteLoop={true} showThumbs={false} showStatus={false} stopOnHover={false} >
                    <div>
                        <img style={{height: 25+'%', width: 2000+'px'}} alt="" src={ImageOne} />
                        <p className="legend"><b>Zeitersparnis ab dem ersten Klick, dank automatisiertem Angebotsmanagement.</b></p>
                    </div>
                    <div>
                        <img alt="" src={ImageTwo} />
                        <p className="legend"><b>Industrie 4.0 beginnt bei der stärksten Wirtschaftskraft, dem Mittelstand.</b></p>
                    </div>
                    <div>
                        <img alt="" src={ImageThree} />
                        <p className="legend"><b>Digitalisierung ist, wenn der Mensch wieder mehr Zeit für die wichtigen Dinge hat.</b></p>
                    </div>
                    <div>
                        <img alt="" src={ImageFour} />
                        <p className="legend"><b>Unsere Mission! Wir wollen der Partner für jedes Unternehmen sein, das sein Angebotsmanagement optimieren, neue Geschäfte abschließen und sein Unternehmenswachstum vorantreiben möchte.</b></p>
                    </div>
                </Carousel>
            </div>
            </Section>
            <div style={{ backgroundColor: '#e9e9e9', alignItems: 'center'}}>
            <Section ref={cbamRef}>
            <img style={{width: 100+'%'}} src={Image_1} alt="Image_1"/>
                <p style={{fontFamily: 'Arial',  fontSize: 1.6+'vw', fontWeight: 'bold', marginTop: 0.5+'%', marginLeft: 11.2+'%', color: '#012850'}}>
                    Warum brauche ich einen CB-AngebotsManager?</p>
                <p style={{fontFamily: 'Arial', paddingBottom: 2+'%', fontSize: 1.1+'vw', marginTop: 0.5+'%', marginLeft: 11.2+'%', color: '#012850'}}>
                    Der CBAM passt zu jedem CRM (Customer-Relationship-Management) Tool und ist als Software-as-a-Service (SaaS) ein Werkzeug, um Einkaufsprozesse<br/>und Lieferantenmanagement zu unterstützen.
                    Unser Ranking hilft bei der Lieferantenauswahl, unterstützt den Entscheidungsprozess, baut<br/>das Lieferantennetzwerk aus und das auf Basis der gewonnenen Angebote,
                    was als Kennzahl, auf Einkaufspreisbasis, bei der Lieferantenbewertung<br/>zusätzlich hilfreich sein wird. Gerade im Handel und Vertrieb können wir mit unserer Datenbasis zur Customer Journey
                    beitragen und das Geflecht<br/>aus Lieferanten minimieren, um den kostengünstigsten und besten Partner zu finden, der dem Kunden das bestmögliche Ergebnis bietet.<br/>
                    Der CBAM ist ein Vertriebsmanagement Tool und minimiert die Einkaufsprozesse bei der Lieferantenauswahl, um deren Kunden schnellstmöglich<br/>das bestmögliche Angebot zu erstellen
                    und damit Oppertunities zu generieren, die vorher nicht möglich gewesen wären. Mit dem CBAM platzieren wir<br/>uns als Plattform zur Problemlösung und Prozessoptimierung, die in
                    modernen Einkaufsorganisationen ihren Platz hat.<br/><br/>Gerade jedes KMU, in dem Zeitmanagement ein wichtiges Thema ist und der Einkauf nicht nur
                    den Beschaffungsprozess verwaltet sondern weitere<br/>multiple Aufgaben, möchten wir helfen Kosten zu sparen. Automatisierung von Anfrage und Angebot im Beschaffungswesen und im Bestellprozess<br/>können
                    in vielen Unternehmen Zeit und Geld sparen und jedes ERP System bereichern.</p>

                <p style={{fontFamily: 'Arial',  fontSize: 1.6+'vw', fontWeight: 'bold', marginTop: 0.5+'%', marginLeft: 11.2+'%', color: '#012850'}}>
                    Für Sie als unser Kunde ist uns wichtig:</p>
                <p style={{fontFamily: 'Arial', paddingBottom: 3.0+'%', fontSize: 1.1+'vw', marginTop: 0.5+'%', marginLeft: 11.2+'%', color: '#012850'}}>
                    User Experience und eine anwenderfreundliche Oberfläche sind uns als CBAM'er sehr wichtig,
                    weshalb wir, als digitale Plattform, stets darauf achten, sowohl<br/>Interface als auch Dashboard up to date
                    zu halten. Sie können die Lieferantendaten aus Ihrer Einkaufssoftware problemlos auf den CBAM
                    übertragen.<br/>Als Add-on für den strategischen Einkauf sind wir auf die barrierefreie Übermittlung
                    von Daten sehr gut vorbereitet.<br/>Mit unserem Ranking erweitern wir das Vertriebsmanagement um zahlreiche Kontaktdaten.
                    Wir können mit unserem Kontaktdatenmanagement den Vertrieb<br/>bei der Lieferantenauswahl und
                    Lieferantensuche unterstützen. Sie können Ihr CRM (Customer-Relationship-Management) Programm um unsere Daten erweitern und<br/>Ihren
                    Datenpool so um zahlreiche Einkaufskontakte vergrößern. Als Supplier-Management-Lösung wollen wir durch unser Ranking auch Lieferanten in
                    diversen<br/>Industriebranchen sichtbar machen und Ihnen so die Möglichkeit geben, neue
                    Lieferantenbeziehungen einzugehen. Sie können Ihr Category Management durch<br/>Aufzeigen neuer Lieferanten ergänzen und erweitern.
                    Schaffen Sie somit ganz neue Lieferketten. </p>

                <figure>
                <img className={"function1"} style={{borderRadius: 7+'px', width: 27.5+'%', marginLeft: 18+'%', }} src={foto_1} alt="Foto1"/>
                <div class={"figcapboxf1"}>
                <figcaption style={{color: '#012850', }}>Wichtige Key Performance Indikators (KPI's), welche für</figcaption>
                <figcaption style={{color: '#012850', }}>den modernen Procure Manager heutzutage unerlässlich sind..</figcaption>
                </div>
                </figure>
                <figure>
                    <img className={"function1"} style={{ borderRadius: 7+'px', width: 27.5+'%', marginTop: -5.8+'%', marginLeft: 18+'%', }} src={foto_3} alt="Foto3"/>
                    <div className={"figcapboxf3"}>
                        <figcaption style={{color: '#012850'}}>Als ERP Ergänzung sind wir das ideale digitale Werkzeug für den Beschaffungsprozess. Durch unsere Lieferterminübersicht können auch</figcaption>
                        <figcaption style={{color: '#012850'}}>Just-in-Time-Lieferungen geplant werden.</figcaption>
                    </div>
                </figure>
                <figure>
                <img className={"function1"} style={{ borderRadius: 7+'px', width: 27.5+'%', marginTop: -29+'%', marginLeft: 53.9+'%', }} src={foto_2} alt="Foto2"/>
                <div className={"figcapboxf2"}>
                <figcapture style={{color: '#012850'}}>Der Angebotsvergleich und das Lieferantenranking helfen bei der Analyse des Marktumfelds und der Erschließung neuer Daten,</figcapture>
                <figcaption style={{color: '#012850'}}>um Lieferketten nachhaltig zu optimieren.</figcaption>
                </div>
                </figure>
                <figure>
                <img className={"function2"} style={{ borderRadius: 7+'px', width: 27.5+'%', marginTop: 1+'%', marginLeft: 53.9+'%', }} src={foto_4} alt="Foto4"/>
                <div className={"figcapboxf4"}>
                <figcaption style={{color: '#012850'}}>Das strategische Lieferantenmanagement kann durch unsere Rankings</figcaption>
                <figcaption style={{color: '#012850'}}>weitere Entscheidungsparameter hinzufügen.</figcaption>
                </div>
                </figure>
                <figure>
                <img className={"function2"} style={{ borderRadius: 7+'px', width: 27.5+'%', marginTop: -13.2+'%', marginLeft: 18+'%', }} src={foto_5} alt="Foto5"/>
                <div className={"figcapboxf5"}>
                <figcaption style={{color: '#012850'}}>Einfache Lieferantenauswahl & Versand von 11 Anfragen.</figcaption>
                <figcaption style={{color: '#012850'}}>Kostenersparnis und Prozessoptimierung mit einem Klick.</figcaption>
                <figcaption style={{color: '#e9e9e9'}}> .</figcaption>
                <figcaption style={{color: '#e9e9e9'}}> .</figcaption>
                <figcaption style={{color: '#e9e9e9'}}> .</figcaption>
                </div>
                </figure>

            </Section>
            </div>
            <div style={{display: 'flex',   backgroundColor: '#dddddd', justifyContent: 'center', alignItems: 'center', textAlign: 'center', justifyItems: 'center', }}>
            <Section style={{paddingTop: -1.5+'%', }} id={sections[2]}>
                <img src={Image_2} alt="Image_2"/>
                <p style={{fontFamily: 'Arial', fontSize: 1.8+'vw', fontWeight: 'bold', marginTop: 0.5+'%',  color: '#012850'}}>
                    Welche Versionen gibt es?</p>
                <table>
                    <thead>

                    <tr>
                        <th style={{width: 380+'px', backgroundColor: '#ffffff', paddingLeft: 50+'px', paddingRight: 50+'px', }} scope="col">Leistungen</th>
                        <th style={{backgroundColor: '#e3e3e3', color: '#1f9d55', paddingLeft: 7+'px', paddingRight: 7+'px',}} scope="col">Free*</th>
                        <th style={{backgroundColor: '#d4d4d4' , paddingLeft: 7+'px', paddingRight: 7+'px',}} scope="col">Klassik</th>
                        <th style={{backgroundColor: '#e3e3e3' , paddingLeft: 7+'px', paddingRight: 7+'px',}} scope="col">Premium</th>
                        <th style={{backgroundColor: '#d4d4d4' , paddingLeft: 7+'px', paddingRight: 7+'px', color: '#ffffff'}} scope="col">(VIP)*</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td style={{backgroundColor: '#ffffff'}}>Organisierte Übersicht über alle Anfragen und Bestellungen</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#e3e3e3', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#d4d4d4', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#e3e3e3', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#d4d4d4', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor: '#ffffff'}}>Automatisierte Anzeige der besten Positionen und Angebote/Lieferanten</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#e3e3e3', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#d4d4d4', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#e3e3e3', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#d4d4d4', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor: '#ffffff'}}>Statusanzeige über offene und erledigte Anfragen</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#e3e3e3', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#d4d4d4', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#e3e3e3', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#d4d4d4', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor: '#ffffff'}}>Professionelle Datensicherheit</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#e3e3e3', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#d4d4d4', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#e3e3e3', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#d4d4d4', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor: '#ffffff'}}>Massenanfragen mit nur wenigen Klicks</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#e3e3e3', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#d4d4d4', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#e3e3e3', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#d4d4d4', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor: '#ffffff'}}>Anfragen direkt über den Angebotsmanager versenden</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#e3e3e3', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#d4d4d4', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#e3e3e3', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#d4d4d4', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor: '#ffffff'}}>Lieferantenkartei anlegen</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#e3e3e3', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#d4d4d4', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#e3e3e3', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#d4d4d4', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor: '#ffffff'}}>Archivierung von Projekten/Anfragen/Angeboten</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#e3e3e3', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#d4d4d4', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#e3e3e3', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#d4d4d4', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor: '#ffffff'}}>Beachtung und Berechnung verschiedener Preiseinheiten</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#e3e3e3', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#d4d4d4', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#e3e3e3', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#d4d4d4', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor: '#ffffff'}}>Bestellung mit nur eineme Klick</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#e3e3e3', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#d4d4d4', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#e3e3e3', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#d4d4d4', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor: '#ffffff'}}>Zugriff auf Anfrage-, Angebots- und Bestellstatistik (Excel Export)</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#e3e3e3', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#d4d4d4', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#e3e3e3', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#d4d4d4', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor: '#ffffff'}}>Lieferantenkartei importieren(**)</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#e3e3e3', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#d4d4d4', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#e3e3e3', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#d4d4d4', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor: '#ffffff'}}>Artikel importieren(***)</td>
                        <td style={{color: 'red', backgroundColor: '#e3e3e3', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>-</td>
                        <td style={{color: 'red', backgroundColor: '#d4d4d4', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>-</td>
                        <td style={{color: 'red', backgroundColor: '#e3e3e3', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                        <td style={{color: 'red', backgroundColor: '#d4d4d4', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor: '#ffffff'}}>Accountpersonalisierung</td>
                        <td style={{color: 'red', backgroundColor: '#e3e3e3', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>-</td>
                        <td style={{color: 'red', backgroundColor: '#d4d4d4', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>-</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#e3e3e3', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#d4d4d4', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor: '#ffffff'}}>Lieferantenauswertung und Ranking</td>
                        <td style={{color: 'red', backgroundColor: '#e3e3e3', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>-</td>
                        <td style={{color: 'red', backgroundColor: '#d4d4d4', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>-</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#e3e3e3', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#d4d4d4', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor: '#ffffff'}}>+3 zusätzliche User</td>
                        <td style={{color: 'red', backgroundColor: '#e3e3e3', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>-</td>
                        <td style={{color: 'red', backgroundColor: '#d4d4d4', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>-</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#e3e3e3', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#d4d4d4', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor: '#ffffff'}}>+6 zusätzliche User</td>
                        <td style={{color: 'red', backgroundColor: '#e3e3e3', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>-</td>
                        <td style={{color: 'red', backgroundColor: '#d4d4d4', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>-</td>
                        <td style={{color: 'red', backgroundColor: '#e3e3e3', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>-</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#d4d4d4', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor: '#ffffff'}}>Persönlicher Ansprechpartner in unserem Supportteam</td>
                        <td style={{color: 'red', backgroundColor: '#e3e3e3', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>-</td>
                        <td style={{color: 'red', backgroundColor: '#d4d4d4', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>-</td>
                        <td style={{color: 'red', backgroundColor: '#e3e3e3', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>-</td>
                        <td style={{color: '#1f9d55', backgroundColor: '#d4d4d4', fontSize: 50+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>•</td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor: '#ffffff', fontWeight: 'bold'}}>Preis pro Monat<br />(bei monatlicher Zahlweise)</td>
                        <td style={{color: '#000000', backgroundColor: '#e3e3e3', fontSize: 16+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>0 €</td>
                        <td style={{color: '#000000', backgroundColor: '#d4d4d4', fontSize: 16+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>15,99 €</td>
                        <td style={{color: '#000000', backgroundColor: '#e3e3e3', fontSize: 16+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>29,99 €</td>
                        <td style={{color: '#000000', backgroundColor: '#d4d4d4', fontSize: 16+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>a. Anfrage</td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor: '#ffffff', fontWeight: 'bold'}}>(Spar-)Preis pro Vierteljahr<br />(bei vierteljährlicher Zahlweise)</td>
                        <td style={{color: '#000000', backgroundColor: '#e3e3e3', fontSize: 15+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>0 €</td>
                        <td style={{color: '#000000', backgroundColor: '#d4d4d4', fontSize: 15+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>42,99 €</td>
                        <td style={{color: '#000000', backgroundColor: '#e3e3e3', fontSize: 15+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>81,99 €</td>
                        <td style={{color: '#000000', backgroundColor: '#d4d4d4', fontSize: 15+'px', textAlign: 'center', paddingBottom: 0+'px', paddingTop: 0+'px',}}>a. Anfrage</td>
                    </tr>
                    </tbody>
                </table>
                <p style={{color: '#012850', fontFamily: 'Arial', fontSize: 12+'px',}}>(VIP)* - Die VIP-Version steht aktuell noch nicht zur Verfügung. Fragen?, dann sysadm@cb-angebotsmanager.de</p>
                <p style={{color: '#012850', fontFamily: 'Arial', fontSize: 12+'px',}}>(**) - Für diesen Import entstehen zusätzliche Kosten - einmalig 50,-€ + 10,-€ bei jedem Update!</p>
                <p style={{color: '#012850', fontFamily: 'Arial', fontSize: 12+'px',}}>(***) - Dieser Import steht aktuell nur auf Anfrage zur Verfügung. Zusatzkosten einmalig 50,-€ + 10,-€ bei jedem Update!</p>
                <p style={{color: '#012850', fontWeight: 'bold', fontFamily: 'Arial', fontSize: 12+'px',}}>Monatliche oder vierteljährliche Zahlweise können Sie im Rahmen der Registrierung wählen.</p>
                <p style={{color: '#1f9d55', fontWeight: 'bold', fontFamily: 'Arial', fontSize: 13+'px',}}>Alle angegebenen Preise verstehen sich zuzüglich des gesetzlich gültigen Mehrwertsteuersatzes.</p>
                <p style={{color: '#dddddd', marginTop: 5+'%', fontSize: 1+'px',}}>-</p>
                <p style={{color: '#012850', fontSize: 24+'px', fontFamily: 'Arial',}}>Warum noch warten?</p>
                <p style={{color: '#012850', fontSize: 24+'px', fontFamily: 'Arial',}}>Werden Sie ein Teil unserer Community aus Pionieren und<br /> profitieren Sie von exklusiven Vorteilen, die Sie nur jetzt erhalten können.</p>
                <a style={{color: '#1f9d55', fontFamily: 'Arial', fontWeight: 'bold', }} href="https://cb-oc.cb-angebotsmanager.de/oc/customreg?transpar=ud" target="_blank" rel="noreferrer">► Hier Klicken und beim CB_Angebotsmanager registrieren.</a>
                <p style={{color: '#012850', fontSize: 17+'px', fontFamily: 'Arial',}}>► oder besuchen Sie uns auf YouTube!</p>
                <div class="youtube">
                <a href="https://www.youtube.com/watch?v=hS3bhXy6elI" target="_blank" rel="noreferrer"><img src={YouTube} alt="B1" width="50" height="auto" /></a>
                </div>
                    <p style={{color: '#dddddd', marginTop: 5+'%', fontSize: 1+'px',}}>-</p>
            </Section>
            </div>
            <div style={{display: 'flex',  backgroundColor: '#43596e', height: 'auto', textAlign: 'center', justifyContent: 'center',}}>
            <Section  id={sections[3]}>

                <p style={{textAlign: 'center', justifyContent: 'center',  color: 'darkgray', fontFamily: 'Arial',}}>Copyright © 2021 Christoph Baumgart - Einzelunternehmung</p>
                <a className="dsr" href="https://cb-angebotsmanager.de/lawsites/Datenschutzbestimmung.pdf" target="_blank" rel="noreferrer">Datenschutzbestimmungen</a>
                <a className="imp" href="https://cb-angebotsmanager.de/lawsites/Impressum.pdf" target="_blank" rel="noreferrer">Impressum</a>
                <a className="nub" href="https://cb-angebotsmanager.de/lawsites/Nutzungsbedingungen.pdf" target="_blank" rel="noreferrer">Nutzungsbedingungen</a>

            </Section>
            </div>
        </div>

        </body>
        </html>
    );
}